import React, { useState, useEffect } from 'react'

// react
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { ButtonGroup, Popover, OverlayTrigger, DropdownButton, Dropdown, ButtonToolbar } from 'react-bootstrap'
// internal
import GraphLegend from './graph-legend.js'
import RodPattern from './rod-pattern.js'
import Arrow from './arrow.js'
import emailLogo from '../../images/paper-plane-blue.png'
import infoLogo from '../../images/info.png'

// external
import * as d3 from 'd3'
import * as hdf5 from 'jsfive/index.js'
import { FaTimes, FaChartLine, FaMinus, FaPlus } from 'react-icons/fa'
import reactCSS from 'reactcss'
import { GithubPicker } from 'react-color'
import GraphLabel from './graph-label.js'
import { GlobalStateContext } from '../../context/global_context_provider'
import {
  GraphDispatchContext,
  GraphStateContext
} from '../../context/graph_context_provider'
import GraphAxis from './graph-axis.js'
import GraphVerticalAxis from './graph-vertical-axis.js'

const abbrevToStation = {}


const buildTitleFromId = cycleId => {
    const stationPart = abbrevToStation[cycleId.substring(0, 2)]
    const unitPart = cycleId.substring(2, 3)
    const cyclePart = cycleId.substring(4, 6)
    return stationPart + ' ' + 'Unit ' + unitPart + ', Cycle ' + cyclePart
}


/* global FileReader */

const _ = require('lodash')

const excludeFromSelection = ['CYEXP', 'DATE', 'NOTCH']
const colorPickerColors = [
  '#B80000',
  '#DB3E00',
  '#FCCB00',
  '#008B02',
  '#006B76',
  '#1273DE',
  '#004DCF',
  '#5300EB',
  '#EB9694',
  '#FAD0C3',
  '#FEF3BD',
  '#C1E1C5',
  '#BEDADC',
  '#C4DEF6',
  '#BED3F3',
  '#D4C4FB',
  '#2494f4',
  '#82c4fc',
  '#FFFFFF',
  '#c0c0c0',
  '#808080',
  '#606060',
  '#404040',
  '#000000'
]


const m = { t: 10, r: 270, b: 50, l: 60 }
const w = 1000
const h = 465

const CycleGraph = props => {
  console.log(buildTitleFromId('LM2C16'))
  const [file, setFile] = useState(null)
  const [hdf, setHdf] = useState(null)
  const [indicator, setIndicator] = useState(null)
  const [rods, setRods] = useState(null)
  const [scales, setScales] = useState(null)
  const [xReset, setXReset] = useState(null)
  const [title, setTitle] = useState(null)
  const state = React.useContext(GraphStateContext)
  const dispatch = React.useContext(GraphDispatchContext)

  console.log(excludeFromSelection)

  const axisUnit = i => (
    state.renderLines.filter(d => d.dominant)
      .filter(d => d.axis === i)
      .map(d => d.units)[0]
  )
  const axisColor = i  => (
    state.renderLines.filter(d => d.dominant)
      .filter(d => d.axis === i)
      .map(d => d.hexColor)[0]
  )

  const [xScaleState, xScaleDispatch] = React.useReducer(
    (state, action) => {
      let brush
      switch (action.type) {
        case 'reset': {
          console.log(state)
          if (!state.xreset) {
            return null
          }
          return {
            xscale: state.xreset,
            xreset: state.xreset
          }
        }
        case 'update': {
          if (!state.xscale) {
            return null
          }
          else {
            console.log(state.xscale(100))
          }
          const updatedScale = d3.scaleLinear().domain([
              state.xscale.invert(action.extent[0]),
              state.xscale.invert(action.extent[1])
          ]).range([m.l + 50, w - m.r - 50])
          return { 
            xscale: updatedScale,
            xreset: state.xreset
          }
        }
        case 'set': {
          return {
            xscale: action.scale.copy(),
            xreset: action.scale.copy()
          }
        }
      }
    }, {
      xscale: null,
      xreset: null
    }
  )

  const [graphState, graphDispatch] = React.useReducer(
    (state, action) => {
      switch (action.type) {
        case 'reset': {
        }
        case 'update': {
        }
        case 'set': {
        }
      }
    }, {
      xscale: null,
      xreset: null
    }
  )

  const handlePointer = e => {
    if (state.data) {
      const xy = d3.pointer(e, e.target)
      if (xy[0] > m.l + 50 &&
          xy[0] < w - m.r - 50 &&
          xy[1] > m.t &&
          xy[1] < h - m.b) {
        const xval = state.xscale.invert(xy[0])
        // const rf = this.state.data.filter(d => d.hasOwnProperty('rods'))
        const rf = state.data.filter(d => 
          Object.prototype.hasOwnProperty.call(d, 'rods')
        )
        const ri = d3.bisector(d => d.CYEXP).right(rf, xval)
        if (ri === 0) {
          setRods(null)
        } else if (rf[ri - 1]) {
          setRods(rf[ri - 1].rods)
        }
        setIndicator(state.renderLines.map(x => {
          const f = state.data.filter(
            d => Object.prototype.hasOwnProperty.call(d, x.key)
          )
          const i = d3.bisector(d => d.CYEXP).center(f, xval)
          return f[i][x.key]
        }))
      }
    }
  }

  const makeBrush = () => {
    // noqa
    let brush
    const updateChart = (e) => {
      if (e.sourceEvent) {
        const extent = e.selection
        const updatedScale = null
        if (!extent) {
          dispatch({ type: 'resetZoom' })
        } else {
          dispatch({ type: 'updateZoom', extent: extent})
          d3.select('.brush').call(brush.move, null)
        }
      }
    }
    const pt1 = [m.l + 50, m.t]
    const pt2 = [w - m.r - 50, h - m.b]
    brush = d3.brushX().extent([pt1, pt2]).on('end', updateChart)
    console.log('making brush!')
    const svg = d3.select('#svg')
    svg.append('g')
      .attr('class', 'brush')
      .attr('clipPath', 'url(#clip)')
      .call(brush)
  }

  useEffect(() => {
    makeBrush()
  }, [])

  useEffect(() => {
    if (props.file) {
      setFile(props.file)
    }
  }, [props.file])

  const loadHdf = () => {
    const reader = new FileReader()
    reader.onloadend = (e) => {
      const barr = e.target.result
      let hdf = new hdf5.File(barr, file.name)
      const cycleId = hdf.keys[0]
      const newTitle = buildTitleFromId(cycleId)
      setTitle(newTitle)
      hdf = hdf.get(hdf.keys[0])
      dispatch({type: 'loadHdf', hdf: hdf})
    }
    if (file) {
      reader.readAsArrayBuffer(file)
      file.value = ''
    }
  }

  useEffect(() => {
    if (file) {
      loadHdf()
    }
  }, [file])

  const styles = reactCSS({
    default: {
      swatch: {
        padding: '4px',
        background: '#fff',
        borderRadius: '0px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.15)',
        display: 'inline-flex',
        cursor: 'pointer'
      },
      popover: {
        position: 'absolute',
        zIndex: '2'
      },
      cover: {
        position: 'fixed'
      }
    }
  })

  const params = [
    { name: 'Moisture Carryover' },
    { name: 'Core Flow' },
    { name: 'Core Temperature' }
  ]

  let renderLines = null
  let rodChanges = null
  if (state.data && state.scales && state.xscale) {
    renderLines = state.renderLines.map((line, i) => {
      const thisData = state.data.filter(d => d[line.key] > 0)
          .map(d => ({ x: d.CYEXP, y: d[line.key]}))
      const yscale = state.scales[line.axis]
      const func = d3.line().x(d => state.xscale(d.x)).y(d => yscale(d.y))
      const path = func(thisData)
      return (
        <path
          key={'line' + i}
          d={path}
          fill='none'
          stroke={line.hexColor}
          strokeWidth='.85'
          clipPath='url(#clip)'
        />
      )
    })
    rodChanges = state.data.filter(d => d.rods).map((d, i) =>
      <Arrow
        class='rod-change'
        x={state.xscale(d.CYEXP)}
        y={h - m.b - 20}
        key={'rodChange' + i}
        onMouseOver={() => console.log('hi!')}
        onMouseLeave={() => console.log('bye!')}
      />
    )
  }

  const handleAddLine = e => {
    dispatch({ type: 'addLine' })
  }
  const handleAxisEvent = (i, e) => {
    const value = parseInt(e.target.value)
    dispatch({ type: 'updateAxis', index: i, value: value })
  }
  const handleColorChoice = (i, color) => {
    dispatch({ type: 'updateColorChoice', index: i, color: color })
  }
  const handleColorPickerClose = (i) => {
    return
  }
  const handleColorPickerEvent = (i) => {
    dispatch({ type: 'toggleColorPicker', index: i })
  }
  const handleCoordEvent = (i, axis, e) => {
    dispatch({
      type: 'updateCoordinate',
      index: i,
      axis: axis,
      value: e.target.value
    })
  }
  const handleDomEvent = (i) => {
    dispatch({ type: 'toggleDominant', index: i })
  }
  const handleKeyEvent = (i, e) => {
    dispatch({ type: 'updateKey', index: i, value: e.target.value })
  }
  const handleRemoveLine = e => {
    dispatch({ type: 'removeLine' })
  }

  const select = (
    (state.fileState)
      ? (
        <>
          <GraphLabel />
          {state.lines.map((l, i) =>
            <Row
              key={'select' + i}
              className='d-flex text-center'
            >
              <Col
                xs='auto'
                className='d-flex justify-content-center text-center'
              >
                <Form.Select
                  className='fw-light'
                  size='sm'
                  value={l.key}
                  style={{ cursor: 'pointer' }}
                  onChange={e => handleKeyEvent(i, e)}
                >
                  {Object.keys(state.fileState.keys)
                    .filter(x => !excludeFromSelection.includes(x))
                    .map((k, j) =>
                    <option key={'selectKey' + i + 'Opt' + j}>
                      {k}
                    </option>)
                  }
                </Form.Select>
              </Col>
              <Col xs='1'>
                {([2, 3].includes(state.fileState.keys[l.key].dim))
                  ? <Form.Control
                      className='justify-content-center text-center'
                      size='sm'
                      type='text'
                      placeholder='i'
                      value={l.x}
                      onChange={e => handleCoordEvent(i, 'x', e)}
                    />
                  : null}
              </Col>

              <Col xs='1'>
                {([1, 2, 3].includes(state.fileState.keys[l.key].dim))
                  ? <Form.Control
                      className='justify-content-center text-center'
                      size='sm'
                      type='text'
                      placeholder='j'
                      value={l.y}
                      onChange={e => handleCoordEvent(i, 'y', e)}
                    />
                  : null}
              </Col>
              <Col xs='1'>
                {([3].includes(state.fileState.keys[l.key].dim))
                  ? <Form.Control
                      className='justify-content-center text-center'
                      size='sm'
                      type='text'
                      placeholder='k'
                      value={l.z}
                      onChange={e => handleCoordEvent(i, 'z', e)}
                    />
                  : null}
              </Col>
              <Col
                xs='auto'
                className='d-flex justify-content-left text-center'
              >
                <Form.Select
                  className='fw-light justify-content-center'
                  id={'axis-dropdown-button' + i.toString()}
                  size='sm'
                  value={l.axis}
                  onChange={e => handleAxisEvent(i, e)}
                >
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                  <option value='4'>4</option>
                </Form.Select>
              </Col>

              <Col xs='3'>
                <Form.Switch
                  className='switch d-flex justify-content-center text-center'
                  type='switch'
                  id={'custom-switch-' + i.toString()}
                  style={{ cursor: 'pointer' }}
                  checked={l.dominant}
                  onChange={e => handleDomEvent(i)}
                />
              </Col>
              <Col xs='1' className='d-flex justify-content-center text-center'>
                <div>
                  <div
                    aria-hidden='true'
                    style={styles.swatch}
                    onClick={e => handleColorPickerEvent(i)}
                  >
                    <div style={{
                      width: '60px',
                      height: '22px',
                      borderRadius: '4px',
                      background: `rgba(${
                        l.rgbaColor.r}, ${
                        l.rgbaColor.g}, ${
                        l.rgbaColor.b}, ${
                        l.rgbaColor.a})`
                    }}
                    />
                  </div>
                  {l.displayColorPicker
                    ? <div
                        style={styles.popover}
                      >
                      <div
                        style={styles.cover}
                        onClick={handleColorPickerClose(i)}
                        aria-hidden='true'
                      />
                      <GithubPicker
                        width='212px'
                        colors={colorPickerColors}
                        color={l.hexColor}
                        onChangeComplete={(c, _e) => handleColorChoice(i, c)}
                      />
                    </div>
                    : null}
                </div>
              </Col>
            </Row>)}
        </>
        )
      : null
  )

  return (
    <>
      <Row className='mb-4'>
        <h1
          className='featurette-heading'
          style={{ fontWeight: '100' }}
        >
          {title}
        </h1>
      </Row>
      <Container xs='auto' fluid>
        <svg
          id='svg'
          params={params}
          width={w}
          height={h}
          onMouseMove={handlePointer}
        >
          <defs>
            <clipPath id='clip'>
              <rect
                width={w - m.l - m.r - 100}
                height={h}
                x={m.l + 50}
                y='0'
              />
            </clipPath>
          </defs>
          <RodPattern
            x={w - m.r + 30}
            y='150'
            h='150'
            rods={rods}
            id='rod-pattern'
          />
          {renderLines}
          <g id='rodChanges' clipPath='url(#clip)'>
            {rodChanges}
          </g>
          {(state.data && indicator)
            ? <GraphLegend
                lines={state.renderLines}
                x={w - m.r + 60}
                y={m.t}
                w='200'
                h='150'
                vals={indicator}
              />
            : null
          }
          {
            (state.xscale)
              ? <GraphAxis y={420} dy={6} scale={state.xscale}/>
              : null
          }
          {
            (state.scales && state.scales[1])
              ? <GraphVerticalAxis x={70} dx={-6} scale={state.scales[1]}/>
              : null
          }
          {
            (state.scales && state.scales[2])
              ? <GraphVerticalAxis x={70} dx={6} scale={state.scales[2]}/>
              : null
          }
          {
            (state.scales && state.scales[3])
              ? <GraphVerticalAxis x={750} dx={-6} scale={state.scales[3]}/>
              : null
          }
          {
            (state.scales && state.scales[4])
              ? <GraphVerticalAxis x={750} dx={6} scale={state.scales[4]}/>
              : null
          }
        </svg>
        <p> </p>
        <hr />
        <Form>
          { (!props.block)
            ? (
                <Form.Group controlId='formFile' className='mb-3'>
                  <Form.Control
                    style={{ width: '35%' }}
                    type='file'
                    size='sm'
                    onChange={e => setFile(e.target.files[0])}
                  />
                  <Form.Text
                    className='text-muted'
                    style={{ fontWeight: '200' }}
                  >
                    Please select an .HDF5 to view
                  </Form.Text>
                </Form.Group>
              )
            : null
          }
          {select}
          <br />
          <Row>
            <Col sm={10}>
              <ButtonToolbar aria-label='MCO.ai Graph Controls'>
                <ButtonGroup className='me-2' aria-label='View Graph'>
                  <OverlayTrigger
                    trigger='hover, focus'
                    placement='top'
                    overlay={
                      <Popover id='popover-view'>
                        <Popover.Body className='text-center mb-0 fw-light'>
                          <strong>View Graph</strong>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button
                      variant='outline-primary'
                      onClick={() => dispatch({ type: 'submit' })}
                    >
                      <FaChartLine />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    trigger='hover, focus'
                    placement='top'
                    overlay={
                      <Popover id='popover-add'>
                        <Popover.Body className='text-center mb-0 fw-light'>
                          <strong>Add Key</strong>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button
                      onClick={handleAddLine}
                    ><FaPlus />
                    </Button>
                  </OverlayTrigger>
                  &nbsp;
                  <OverlayTrigger
                    trigger='hover, focus'
                    placement='top'
                    overlay={
                      <Popover id='popover-remove'>
                        <Popover.Body className='text-center mb-0 fw-light'>
                          <strong>Remove Key</strong>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button
                      onClick={handleRemoveLine}
                    ><FaMinus />
                    </Button>
                  </OverlayTrigger>
                  &nbsp;
                  <OverlayTrigger
                    trigger='hover, focus'
                    placement='top'
                    overlay={
                      <Popover id='popover-clear'>
                        <Popover.Body className='text-center mb-0 fw-light'>
                          <strong>Clear Graph</strong>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button
                      // size='sm'
                      variant='primary'
                      onClick={() => {
                        window.location.reload()
                      }}
                    ><FaTimes />
                    </Button>
                  </OverlayTrigger>
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  )
}

export default CycleGraph