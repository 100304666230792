// react
import React, { useEffect, useState } from 'react'
// react bootstrap components
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
// internal components
import CycleGraph from 'components/graph/cycle-graph-2'
import { GlobalDispatchContext } from 'context/global_context_provider'
import GraphContextProvider from 'context/graph_context_provider'
import Layout from 'components/layout'
// internal functions
import download from 'tools/aws/download'

const IndexPage = (props) => {
  const dispatch = React.useContext(GlobalDispatchContext)
  const [file, setFile] = useState(null)
  const [block, setBlock] = useState(false)
  const [station, setStation] = useState('-')
  const [unit, setUnit] = useState('-')
  const [cycle, setCycle] = useState('-')
  const hash = props.location.hash.substring(1)
  useEffect(async () => {
    if (hash) {
      setBlock(true)
      console.log(hash)
      const result = await download('mco', hash, dispatch)
      setStation(result[1].station)
      setUnit(result[1].unit)
      setCycle(result[1].cycle)
      setFile(result[0])
    } else {
      setBlock(false)
    }
  }, [hash])
  return (
    <GraphContextProvider>
      <Container>
        <CycleGraph file={file} block={block}/>
      </Container>
    </GraphContextProvider>
  )
}

IndexPage.Layout = Layout

export default IndexPage
